import type {
  Category,
  Product,
} from "~/commerce-sap/.server/api/generated/__generated_apis";

export const isGiftCard = (product: Product): boolean => {
  return !!(
    product?.categories &&
    product.categories.some(
      (category: Category) => category.code === "gift-card",
    )
  );
};
